.header {
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 100;

    .iconsWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        

        nav{

            ul{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                li{
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        .social{
            display: flex;
            align-items: center;
            margin-right: 20px;
        }
    }
}