html, body {
    height: 100%;
}

.main {

    display: flex;
    height: 100%;
}

.mainContent {

    width: 100%;
    margin-left: 80px;
    margin-bottom: 50px;
}

a {
    color: black;
}

@media (max-width: 768px){
    .main{
        padding-top: 60px;
    }
    .mainContent{
        margin-left: 0;
    }
}