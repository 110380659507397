.sidebar {
    width: 80px;
    height: 100%;
    position: fixed;

    .iconsWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .social{
            margin-bottom: 30px;
        }
    }
}