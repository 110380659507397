.nameSection {
    display: flex;
    justify-content: space-between;

    .photo {
        display: flex;
    }

    .content {
        margin-left: 20px;
    }

    @media (max-width: 1024px){
        flex-direction: column;

        .photo{
            flex-direction: column;
        }
        .content{
            margin-top: 20px;
            margin-left: 0;
        }
    }

    @media (max-width: 768px){
        
        justify-content: center;
        align-items: center;

        .photo{
            justify-content: center;
            align-items: center;
        }
        .content{
            margin-top: 20px;
            text-align: center;
            font-size: medium;
        }
    }
    a{
        color: white;
    }
    a:hover{
        color: lightgray;
    }
}